/* Mobile first */
#appContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#fade-to-white {
  position: fixed;
  background-color: white;
  height: 100%;
  width: 100%;
  inset: 0;
  display: none;
  opacity: 0;
  transition: opacity 1s ease-in;
}

#fade-to-white.prepped {
  display: block;
}

#fade-to-white.active {
  opacity: 1;
}
