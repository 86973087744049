#clock-background {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    transition: background-color 0.1s linear;
}

#clock {
    max-width: 900px;
}


@keyframes MoveUpDown {
  0%, 100% {
    transform:  translateY(0%)
  }
  50% {
    transform: translateY(1%)
  }
}
