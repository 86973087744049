div.help {
    position: fixed;
    display: flex;
    float: right;
    margin: 1rem;
    bottom: 0;
    left: 0;
}

button.help-button {
    background: white;
    border: none;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

span.help-message {
    margin-top: 3px;
    margin-right: 0.5rem;
    color: white;
}

.help {
    font-family: 'Lato', sans-serif;
    color: #f8f8ff;
}

.help h1 {
    font-family: 'Ciznel';
}

.help h2 {
    font-family: 'Ciznel';
}

.help a {
    color: #AAAA9A;
}