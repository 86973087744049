#second-hand line {
	stroke-width: 0.5;
}

#minute-hand line {
	stroke-width: 1.3;
	stroke-linecap: round;
}

#hour-hand line {
	stroke-width: 2;
	stroke-linecap: round;
}

.hands-cover {
	stroke-width: 2;
	fill: #d0b16b;
}

.hour-label {
	font-family: 'Amita', 'Brandish', serif;
    font-size: 9px;
    fill: #d0b16b;
    stroke: #112745;
    stroke-width: 0.35;
}
