#AnswerForm {
    color: white;
    background-color: #021b2f;
    text-align: center;
    width: 100%;
}

#AnswerForm .close-button {
    color: white;
    background: none;
    border: none;
    position: fixed;
    right: 1em;
    top: 1em;
}

#AnswerForm form {
    padding: 2em;
}

input#Answer {
    text-transform: uppercase;
}

input#Answer, #AnswerForm button {
    font-size: min(6vw, 36px);
}

#AnswerForm .submit-button {
    margin-top: 0.5em;
    padding: 0.25em 0.5em;
    border: none;
    border-radius: 5px;
    background: green;
    color: white;
    text-transform: uppercase;
    transition: background-color 0.2s ease;
}

#AnswerForm .submit-button:hover {
    background: seagreen;
    box-shadow: 0 2px 10px white;
}

#AnswerForm .submit-button:focus, #AnswerForm .submit-button:active {
    background: darkgreen;
    box-shadow: 0 2px 10px white;
}

button {
    cursor: pointer;
}
