#mouse-left-button:hover ellipse, #mouse-right-button:hover ellipse {
    fill: #b56b32;
}

#mouse-left-button:hover path, #mouse-right-button:hover path {
    fill: #f5c572;
}

#mouse-left-button:active ellipse, #mouse-right-button:active ellipse {
    stroke-width: 1px;

}

@keyframes MoveUpDownMouse {
  0%, 100% {
    transform:  translateY(0%)
  }
  50% {
    transform: translateY(1%)
  }
}
