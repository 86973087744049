rect.frame-rect {
    fill: black;
    stroke: none;
    cursor: pointer;
    transition: fill 0.1s ease;
}

g.frame-incorrect:hover rect.frame-rect {
    fill: #1a4988;
}

g.frame-correct rect.frame-rect {
    fill: url(#marble);
    cursor: default;
}

g.frame-correct:focus {
    outline: none;
}

text.frame-text {
    font-family: 'Cinzel', serif;
    font-size: 17.78px;
    kerning: auto;
    stroke: none;
    fill:  white;
}

text.frame-text tspan {
    cursor: pointer;
}

text.frame-text-selected {
    fill:  red;
}
