.moonback {
    fill: black;
}

.moon {
    fill: silver;
}

.sun {
    fill: gold;
}
